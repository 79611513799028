import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Performance is a priority at GitHub, and the first approach is to improve the speed of our processes. However, there are times when we can't avoid making users wait. In these cases, maintaining `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/visibility-system-status/"
      }}>{`visibility of system status`}</a>{` through loading states can help users feel stable and in control.`}</p>
    <p>{`Without indicating that something is loading, users may think a process has silently failed. This can lead to confusion and frustration.`}</p>
    <p>{`Some of our components already have loading states built in. Rely on component-specific patterns before designing your own loading experiences.`}</p>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/react/storybook/?path=/story/components-datatable-features--with-loading"
        }}>{`Data table`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/selectpanel#loading"
        }}>{`Select panel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tree-view#asynchronously-loading-child-nodes"
        }}>{`Tree view`}</a></li>
    </ul>
    <h2>{`Loading indicators`}</h2>
    <p>{`Loading indicators reassure users that their request is actively being executed, and can sometimes be used to set an expectation for how long they'll be waiting. They may even reduce the users' perception of time.`}</p>
    <p>{`Animation in loading indicators helps reassure the user that the process is active and the system isn't frozen. However, animation can become distracting or overwhelming. Loading indicators' animation should be subtle, and we should avoid letting a page get overtaken by too many of them.`}</p>
    <h3>{`Determinate loading indicators`}</h3>
    <Box display="flex" flexDirection="column" sx={{
      gap: 5,
      'h4': {
        marginTop: 0
      }
    }} mdxType="Box">
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        alignItems: 'flex-start',
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <p>{`Determinate loading indicators may be used when the progress or duration of a process is known or can be estimated. This type of loading gives the user an idea of how much longer they'll have to wait.`}</p>
          <p>{`Determinate loading indicators work best for processes that are likely to take longer (approximately 3 or more seconds). Shorter waiting times don't give the user enough time to process the information conveyed by a determinate loading indicator.`}</p>
        </Box>
        <img width="456" alt="A progress bar with % completed text" src="https://github.com/primer/design/assets/2313998/973ab0ca-4f5c-4e06-98f2-e66fdf706943" />
      </Box>
      <h3>{`Indeterminate loading indicators`}</h3>
      <div>
        <p>{`Indeterminate loading indicators may be used when the progress or duration of a process is variable or unknown.`}</p>
        <p>{`This type of loading gives the user a sense that something is happening, but not how long it will take.`}</p>
      </div>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        alignItems: 'flex-start',
        gap: 3
      }} mdxType="Box">
        <div>
          <h4>{`Spinner`}</h4>
          <p>{`A `}<a parentName="p" {...{
              "href": "/ui-packages/spinner"
            }}>{`spinner`}</a>{` is the most versatile indeterminate loading indicator. It may be used in place of content that is loading, or adjacent to a loading message`}</p>
        </div>
        <img width="456" alt="PR status checks being replaced by a spinner" src="https://github.com/primer/design/assets/2313998/4f4e897e-bbda-4cbc-bd0f-962450c6b5b6" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Content skeleton`}</h4>
          <p>{`For large areas of loading content, the loading content may be replaced by a vague representation of the content. This shows users the general shape of the page and could make them perceive the real content as loading faster.`}</p>
          <p>{`Skeleton loaders may be used when we can approximate the visual shape the loaded content will create on the page.`}</p>
          <p>{`This kind of loading indicator can be rendered using the following components:`}</p>
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/components/skeleton-avatar"
              }}>{`Skeleton avatar`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/components/skeleton-text"
              }}>{`Skeleton text`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/components/skeleton-box"
              }}>{`Skeleton box`}</a></li>
          </ul>
        </Box>
        <CustomVideoPlayer width="456" loop autoPlay src="https://github.com/primer/react/assets/2313998/4bb7b7b6-482f-46c8-97d8-4b560066d287" mdxType="CustomVideoPlayer" />
      </Box>
      <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
        gap: 3
      }} mdxType="Box">
        <Box flex={1} mdxType="Box">
          <h4>{`Animated icon or illustration`}</h4>
          <p>{`An animated icon or illustration may be used as a "branding moment" for special loading states. This should be used sparingly.`}</p>
        </Box>
        <CustomVideoPlayer width="456" loop autoPlay src="https://github.com/primer/react/assets/2313998/d5a49e2d-315c-4d55-b929-ef2e6a648712" mdxType="CustomVideoPlayer" />
      </Box>
    </Box>
    <h4>{`Loading text`}</h4>
    <p>{`Text may accompany any of the above loading indicators to give the user more context about the process.`}</p>
    <h2>{`Adapting to different wait times`}</h2>
    <p>{`The following is meant as general guidance since we can't accurately predict how long a process will take. It's difficult to give even a rough estimate of how long a process will take due to unknown variables such as network speed, device performance, and server load.`}</p>
    <img width="960" alt="3 images: 1. crossed out loader; 2. spinner; 3. progress bar with text;" src="https://github.com/primer/design/assets/2313998/7b42b9aa-c517-4620-833a-d12b7e6084bd" />
    <p><strong parentName="p">{`Less than 1 second:`}</strong>{` Don't show a loading state. Seeing a loading indicator flash on the screen could be distracting and make the product feel slower than it is.`}</p>
    <p><strong parentName="p">{`1–3 seconds:`}</strong>{` Use an indeterminate loading state. The user won't have enough time to process the information in a determinate loading state, and potentially cause frustration or confusion about missing information.`}</p>
    <p><strong parentName="p">{`3–10 seconds:`}</strong>{` Use a determinate loading state if possible to keep the user informed about why they're waiting so long.`}</p>
    <p><strong parentName="p">{`More than 10 seconds:`}</strong>{` Use a determinate loading state and avoid blocking other interactions by treating the process as a background task if possible. This reduces the interruption caused by a lengthy process, and could make wait times feel shorter if a user can shift their focus to other tasks. For example, the user can still interact with a pull request while they're waiting for CI actions to run.`}</p>
    <h2>{`Lifecycle of a loading state`}</h2>
    <img width="960" alt="4 images: 1. explicitly excluded loading indicator; 2. button in loading state; 3. success banner; 4. error banner" src="https://github.com/primer/design/assets/2313998/91f1ecd4-78e3-4178-a505-749ab05f3efa" />
    <p><strong parentName="p">{`Process initiated:`}</strong>{` The moment just before loading begins. Usually initiated by a user action such as clicking a button or submitting a form`}</p>
    <p><strong parentName="p">{`Process in progress:`}</strong>{` The process is underway. This is the actual loading state.`}</p>
    <p><strong parentName="p">{`Process complete successfully:`}</strong>{` The process has finished successfully, and the user can continue with their task. If the user isn't navigated to a new context when the task has completed, this step may include some indication of success, such as a green checkmark or a success message.`}</p>
    <p><strong parentName="p">{`Process failed:`}</strong>{` There was an error, and the process could not be completed. This step should include an error message that explains what went wrong and what the user's next time may be. For example, if the user can retry the process, include a button to do so.`}</p>
    <h2>{`Incremental loading`}</h2>
    <p>{`Whenever possible, show each item in a collection as soon as it loads. Don't wait for every item in the collection to load before showing the items.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <CustomVideoPlayer width="456" loop src="https://github.com/primer/design/assets/2313998/6e7f87df-0d3e-4a58-a001-dd09e4510cc3" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Show content as soon as it's loaded.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <CustomVideoPlayer width="456" loop src="https://github.com/primer/design/assets/2313998/a06a4ee2-cbe6-4ec0-8551-16381c7bee49" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Don't wait for all content in a collection to be loaded before showing it.</Caption>
  </Dont>
    </DoDontContainer>
    <p>{`Whenever possible, prioritize the most important pieces of data to load first. This shows the user the most important content first and gives them a chance to interact with things sooner. This could be especially helpful for somebody on a slow network.`}</p>
    <img width="960" alt="4 images of a repo card from the feed loading: 1. Skeleton; 2. Text; 3. Actions; 4. Images" src="https://github.com/primer/design/assets/2313998/ea5725c7-bec2-43a0-8cd9-43fba1a27a51" />
    <h2>{`Scoping loading indicators to page content`}</h2>
    <p>{`A loading indicator should be placed nearest to the content they are standing in for. Avoid creating a jarring layout shift when the loaded content replaces the loading indicator.`}</p>
    <p>{`Replace loading indicators with loaded content or error feedback as soon as possible to avoid burdening users with unnecessarily long wait times.`}</p>
    <h3>{`Interstitial loading screen`}</h3>
    <CustomVideoPlayer width="960" loop src="https://github.com/primer/design/assets/2313998/b5cc3e34-cac1-408f-bf9c-58a8d1655cc7" mdxType="CustomVideoPlayer" />
    <p>{`Some processes navigate you to a new page once they've completed, and use an interstitial loading page as a transition. This should be done sparingly because loading a new page is a bigger interuption than showing a loading indicator in context on the page.`}</p>
    <p>{`Interstitial loading screens are useful for:`}</p>
    <ul>
      <li parentName="ul">{`processes that take a longer time (approximately 3 seconds or more) and result in a significant change such as the creation of a new repo`}</li>
      <li parentName="ul">{`blocking the UI to prevent interruptions or changes to data`}</li>
    </ul>
    <h3>{`Large areas`}</h3>
    <img width="960" alt="3 images: 1. Spinner replacing status check box; 2. Spinner replacing PR/issues sidebar sections; 3. Spinner replacing modal content" src="https://github.com/primer/design/assets/2313998/9347a9a1-088b-4bf0-829a-a38ba1644525" />
    <p>{`Position your loading state in the center of the region, but ensure it's still visible within the viewport.`}</p>
    <h3>{`Small areas`}</h3>
    <p>{`To avoid information overload and visual noise, consider replacing a series of adjacent loading indicators with a single loading indicator.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <CustomVideoPlayer width="456" loop src="https://github.com/primer/design/assets/2313998/eed9b892-39a1-45d4-b22b-5c92a4842c1d" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Reduce the number of loading indicators shown on a page.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <CustomVideoPlayer width="456" loop src="https://github.com/primer/design/assets/2313998/95960988-aab0-4c63-9a4e-441cde558ef4" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Don't show a loading indicator for every piece of loading content on the page.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Accessibility`}</h2>
    <h3>{`Conveying loading indicators`}</h3>
    <p>{`Loading indicators are visual and need to be accessible to assistive technologies. This can be done with a text element we associate using `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`, or attributes such as `}<inlineCode parentName="p">{`alt`}</inlineCode>{` (on an `}<inlineCode parentName="p">{`img`}</inlineCode>{` element) and `}<inlineCode parentName="p">{`title`}</inlineCode>{` (on a `}<inlineCode parentName="p">{`svg`}</inlineCode>{` element). The loading text element may be visually hidden.`}</p>
    <p>{`You may default to a generic label such as "loading", but try to be specific when possible. For example, when a spinner replaces status checks in a pull request, it could have the label "loading status checks".`}</p>
    <h3>{`Avoiding over-announcing loading states`}</h3>
    <p>{`If you're using multiple loading indicators to convey a cluster of loading content, be mindful about how many announcements will be read aloud to screen reader users.`}</p>
    <p>{`Since we discourage showing too many loading indicators on the page at once, this problem is most likely to happen when using a `}<a parentName="p" {...{
        "href": "#content-skeleton"
      }}>{`content skeleton`}</a>{` loading indicator.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/70905325-7561-417d-9fcd-2aae7c910bc8" role="presentation" width="456" />
    <Caption mdxType="Caption">Have a single loading announcement for a collection of skeleton loaders</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/52e5ee70-9e14-4fa8-92d6-c03ac64dcddd" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't have a loading announcement for every piece of loading content</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Conveying status`}</h3>
    <p>{`Communicate when a process has been initiated, when it's in progress, and when it has been completed or if it has failed.`}</p>
    <p>{`There are many cases where it will be obvious that a process has completed or failed, and we won't need an additional announcement. For example, a page load or a new element getting focused.`}</p>
    <p>{`For other cases, the status will need to be manually conveyed to assistive technologies. Use a `}<inlineCode parentName="p">{`role="status"`}</inlineCode>{` message or put content in an `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` element that communicates the status of a process.`}</p>
    <h4>{`Filter results`}</h4>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      alignItems: 'flex-start',
      gap: 3,
      mb: 3
    }} mdxType="Box">
      <div>
        <p>{`If the user needs to wait for filtering to complete, a screen reader should announce how many results were returned from filtering. For example: "No items match the filter", or "5 items match the filter".`}</p>
        <p>{`To ensure that assistive technology is informed about the filter update, put the message in an element that has a `}<inlineCode parentName="p">{`role`}</inlineCode>{` of `}<inlineCode parentName="p">{`"status"`}</inlineCode>{`. The element with `}<inlineCode parentName="p">{`role="status"`}</inlineCode>{` must always be rendered, not just when the message should be announced.`}</p>
        <p>{`Implementations of this pattern can be seen in the following examples:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://primer.style/view-components/lookbook/inspect/primer/alpha/auto_complete/playground"
            }}>{`Lookbook - Autocomplete`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://primer.style/react/storybook/?path=/story/components-actionlist-examples--async-list-with-spinner"
            }}>{`Storybook - ActionList`}</a></li>
        </ul>
      </div>
      <img width="456" role="presentation" src="https://github.com/primer/design/assets/2313998/61171c17-274d-416b-81a7-825a610fdd24" />
    </Box>
    <h3>{`Preventing partially loaded content from being announced`}</h3>
    <p>{`If an `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` region of a page is being updated, set `}<inlineCode parentName="p">{`aria-busy="true"`}</inlineCode>{` on the live region until the updates are complete. Then, set `}<inlineCode parentName="p">{`aria-busy="false"`}</inlineCode>{`. This prevent assistive technologies from announcing updates until the updates are complete.`}</p>
    <h3>{`Focus management`}</h3>
    <p>{`Follow existing focus patterns. When focus needs to move, it should go to the most logical and predictable. Before implementing your own focus management strategy for a loading state, confirm that the component hasn't already implemented it. For example, when a `}<a parentName="p" {...{
        "href": "/components/dialog"
      }}>{`dialog`}</a>{` closes, focus is automatically moved back to the control that opened the dialog.`}</p>
    <p>{`If the completed process results in navigating to a new page, just rely on the browser's default focus behavior for new pages.`}</p>
    <p>{`If the process fails and a validation message is shown, move focus to the first focusable element in the error message. See the `}<a parentName="p" {...{
        "href": "/ui-patterns/forms/overview#validation-on-submit"
      }}>{`form validation guidelines`}</a>{` for more info.`}</p>
    <p>{`If the process loads new content, move focus to first focusable element in the newly loaded content. This pattern is also described in the `}<a parentName="p" {...{
        "href": "/components/tree-view#focus-out"
      }}>{`tree view focus guidelines`}</a>{`.`}</p>
    <h3>{`Disabling controls during loading`}</h3>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <Box flex={1} mdxType="Box">
        <p>{`You may disable form controls after the user submits the form to avoid confusion over whether changes that were made during the submission process will be saved.`}</p>
        <p>{`However, the button that initiates the a loading process may not be semantically disabled using `}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{`. For more information about button loading states, see the `}<a parentName="p" {...{
            "href": "/components/button#loading"
          }}>{`loading section`}</a>{` of the button component guidelines.`}</p>
      </Box>
      <CustomVideoPlayer width="456" loop autoPlay src="https://github.com/primer/design/assets/2313998/b138567f-3ba8-49ab-9fa1-80a4fe30b034" mdxType="CustomVideoPlayer" />
    </Box>
    <h3>{`Motion considerations`}</h3>
    <p>{`Animated loading indicators help reassure the user that the system isn't frozen, and should not be disabled when for users that prefer reduced motion. However, the animation in loading indicators should be kept as subtle as possible. Large, flashy animations would be harmful to users with sensitivity to motion.`}</p>
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/degraded-experiences"
        }}>{`Graceful degradation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/saving"
        }}>{`Patterns for saving`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/progress-bar"
        }}>{`Progress bar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-avatar"
        }}>{`Skeleton avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-box"
        }}>{`Skeleton box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-text"
        }}>{`Skeleton text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/spinner"
        }}>{`Spinner`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      